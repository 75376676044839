/**
 * Body tag styling
 */
body {
    @extend %bg-black;
    //overflow-x: hidden;
    color: color(white);
    font-family: $body-font;
    font-weight: 400;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    //-moz-font-smoothing: always;
    -moz-osx-font-smoothing: grayscale;
}

/**
 * A-tag styling
 */
a {
    color: inherit;
    text-decoration: none;
    
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @extend %title-base;
}

/**
 * Reset strong for bold styling kuch
 */
strong {
    font-weight: 900;
}

/**
 * Window.breakpoint
 * [1] For mobile
 * [2] For tablet
 * [3] For desktop
 * [4] For ultra desktop
 */
@include mappy-query(mobile) { // [1]
    body {
        @include define-breakpoint('breakpoint-mobile');

    }
}

@include mappy-query(tablet) { // [2]
    body {
        @include define-breakpoint('breakpoint-tablet');
    }
}

@include mappy-query(desktop) { // [3]
    body {
        @include define-breakpoint('breakpoint-desktop');
    }
}

@include mappy-query(large-desktop) { // [4]
    body {
        @include define-breakpoint('breakpoint-large-desktop');
    }
}
