.portfolio-project-image {
    @extend %animate-defer;   
    clear: both;
    overflow: hidden;
    padding:0 4px 4px 4px;
    position: relative;
    
    img {
        float: left;
    }
    
    .media-type-wrapper {
        position: relative;
    }
    
    .image-svg {
        position: absolute;
        width: 100%;
        height: 100%;
        top:0;
        left:0;
    }
    
    &.is--browser {
        //@extend %aspect-ratio;
        width: 100%;
        &::before {
            padding-top: 75.48%;
        }
        .media-type-wrapper {
            width: 100%;
            padding-top: 5.72%;
            
        }
        
    }
    
    &.is--mobile {
        max-width: 300px;
        width: 40%;
        height: auto;
        display: inline-block;
        margin:0 3%;
        position: relative;
        
               
        .media-type-wrapper {
            padding-top: 191.81%;
            
            .image-svg {
                z-index: 2;
            }    
        }
        
        img {
            position: absolute;
            z-index: 3;
            width: 86%;
            height: auto;
            top:8%;
            left:7%;
        }
    }
    
    img {width: 100%;height: auto;}
}

@include mappy-query(mobile) {
    .portfolio-project-image {
        padding: 0;
        
        &.is--mobile {
            width: 80%;
        }
    }
}
