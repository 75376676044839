/**
* Portfolio item title
* @note animation see animate.scss
**/
.portfolio-client-item-title {
    @extend %title-base;
    @extend %title-page;
    @extend %uppercase;

    position: absolute;
    line-height: 1;
    top:50%;
    left:0;
    width: 440px;
    z-index: 5;
    text-shadow: 0 2px 15px rgba(#000,.7);
    text-rendering: optimizeLegibility;
    //transition:transform .3s ease-in-out, left .3s ease-in-out, text-align .2s ease-in-out;
    //will-change: text-align, transform, left;
    
}

//[1]
/*
.portfolio-client-item-title.is--active {
    transform:translate3d(-50%,-50%, 0);
    left:50%;
    text-align: center;
}
*/

    
    