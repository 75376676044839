.button-close,
.modal-button-close {
    z-index: 40;
    width: 44px;
    height: 44px;
    position: absolute;
    right:0;
    top:10px;
    background:none;
    border:0;
    outline: 0;
    overflow: hidden;
    text-indent:120%;
    cursor: pointer;
    
    .icon {
        fill:#fff;
        position: absolute;
        width: 70%;
        height: 70%;
        display: block;
        top:15%;
        left:15%;
    }
    
    &:hover .icon {
        animation:spin ease-in-out .5s 1 forwards;
    }
}

.modal-button-close {
    position: fixed;
    right: 2*$gutter;
    top: 2*$gutter;
    z-index: 1000;
}

@include mappy-query(mobile) {
    .button-close .icon,
    .modal-button-close .icon {
        width: 60%;
        left:20%;
        top:20%;
    }
}