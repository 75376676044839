.modal-overlay {
    left:-100%;
    background-color:rgba(color(black),.5);
    width: 100%;
    height: 100%;
    position: fixed;
    top:0;
    left:0;
    z-index: 899;
    opacity: 0;
}