.portfolio-note {
    //position: absolute;
    //bottom: 0;
    //left: 90px;
    position: relative;
    font-size: rem(15);
    font-weight: 300;
    display: inline-block;
    padding-left: 29px;
    color: transparent;
    opacity: .7;
    will-change: color, transform;
    
    &::before {
        display: inline-block;
        position: absolute;
        width: 0px;
        height: 0px;
        border: 1px solid rgba(#fff, .9);
        border-width: 0 0 1px 1px;
        content: '';
        left: 0;
        top: 1px; 
        opacity: 0;
        will-change: height, width, opacity; 
    }
    
    &::after {
        position: absolute;
        width: 0px;
        height: 1px;
        left: 100%;
        margin-left: 5px;
        border-left-width: 0;
        top: 50%;
        background-color: rgba(#fff, .9);
        content: '';
        opacity: 1;
    }
}

.portfolio-item:hover .portfolio-note {
    
    animation-name: color-in;
    animation-duration: .6s;
    animation-delay: .4s;
    animation-fill-mode: both;
    
    
    &::before {
        animation-name: block-in;
        animation-delay: .1s;
        animation-duration: .5s;
        animation-fill-mode: both;
    }
    
    &::after {
        animation-name: after-in;
        animation-delay: .5s;
        animation-duration: .2s;
        animation-fill-mode: both;
    }
    
}

@keyframes after-in {
    0% { width: 0;  }
    100% { width: 5px;}
}


@keyframes color-in {
    0% { color: rgba(#fff, 0); transform: translate3d(-5px, 0, 0);  }
    100% { color: rgba(#fff, 1); transform: translate3d(0, 0px, 0);}
}

@keyframes block-in {
    0% { height: 0; width: 0px; opacity: 0; }
    50% { height: 10px; width: 1px; opacity: 1;}
    100% { height: 10px; width: 24px; opacity: 1;}
}

